import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../auth/users/userSlice'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './Inspection.css'
import Axios from 'axios'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import dbData from '../db/dbdata.json'
import dbMakes from '../db/dbMakes.json'
import dbModels from '../db/dbModels.json'
import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
import logo from '../assets/image/logo.png'

  
const Inspection = () =>  { 
        const user = useSelector(selectUser)

        const [ idInspection, setidInspection ] = useState('')
        const [ lincense, setlincense ] = useState('')
        const [ date, setdate ] = useState('')
        const [ year, setyear ] = useState('')
        const [ make, setmake ] = useState('')
        const [ model, setmodel ] = useState('')
        const [ vin, setvin ] = useState('')
        const [ odometer, setodometer ] = useState('')

         const [ coolingWaterPump, setcoolingWaterPump ] = useState('') 
         const [ coolingEngineFanMotor, setcoolingEngineFanMotor ] = useState('') 
         const [ coolingFanClutchShroud, setcoolingFanClutchShroud ] = useState('') 
         const [ coolingCoolantRecoveryUnit, setcoolingCoolantRecoveryUnit ] = useState('') 
         const [ coolingBeltsHoses, setcoolingBeltsHoses ] = useState('') 
         const [ coolingTemperatureLightGauge, setcoolingTemperatureLightGauge ] = useState('') 
         const [ coolingFluidLeaks, setcoolingFluidLeaks ] = useState('') 
         const [ coolingFuelPumpTankLines, setcoolingFuelPumpTankLines ] = useState('') 
         const [ coolingDieselInjectorsPump, setcoolingDieselInjectorsPump ] = useState('') 
         const [ transmissionFluidLevelCondition, settransmissionFluidLevelCondition ] = useState('') 
         const [ transmissionShiftPoints, settransmissionShiftPoints ] = useState('') 
         const [ transmissionSlippingBinding, settransmissionSlippingBinding ] = useState('') 
         const [ transmissionNoise, settransmissionNoise ] = useState('') 
         const [ transmissionMounts, settransmissionMounts ] = useState('') 
         const [ transmissionFluidLeaks, settransmissionFluidLeaks ] = useState('') 
         const [ brakeFluidLeaksLinesFiltering, setbrakeFluidLeaksLinesFiltering ] = useState('') 
         const [ brakeMasterCylinder, setbrakeMasterCylinder ] = useState('') 
         const [ brakePumpBooster, setbrakePumpBooster ] = useState('') 
         const [ brakeLiningsFronts, setbrakeLiningsFronts ] = useState('') 
         const [ brakeLiningsRears, setbrakeLiningsRears ] = useState('') 
         const [ treadLeftFront, settreadLeftFront ] = useState('') 
         const [ treadLeftRear, settreadLeftRear ] = useState('') 
         const [ enginePerformance, setenginePerformance ] = useState('') 
         const [ engineNoise, setengineNoise ] = useState('') 
         const [ engineOilPressureLightGauge, setengineOilPressureLightGauge ] = useState('') 
         const [ engineMotorMounts, setengineMotorMounts ] = useState('') 
         const [ engineExhaustLeak, setengineExhaustLeak ] = useState('') 
         const [ engineOilLevelCondition, setengineOilLevelCondition ] = useState('') 
         const [ engineOilLeaks, setengineOilLeaks ] = useState('') 
         const [ engineOilSmoke, setengineOilSmoke ] = useState('') 
         const [ driveVibration, setdriveVibration ] = useState('') 
         const [ driveNoise, setdriveNoise ] = useState('') 
         const [ driveFluidLeaks, setdriveFluidLeaks ] = useState('') 
         const [ driveCVUJoints, setdriveCVUJoints ] = useState('') 
         const [ suspensionControlHarms, setsuspensionControlHarms ] = useState('') 
         const [ suspensionBallJoints, setsuspensionBallJoints ] = useState('') 
         const [ suspensionWheelBearings, setsuspensionWheelBearings ] = useState('') 
         const [ suspensionTorsionBarsSprings, setsuspensionTorsionBarsSprings ] = useState('') 
         const [ suspensionStabilizerShaftLinks, setsuspensionStabilizerShaftLinks ] = useState('') 
         const [ suspensionMacPhersonStruts, setsuspensionMacPhersonStruts ] = useState('') 
         const [ steeringFluidLevelLeak, setsteeringFluidLevelLeak ] = useState('') 
         const [ steeringPump, setsteeringPump ] = useState('') 
         const [ steeringLinkage, setsteeringLinkage ] = useState('') 
         const [ steeringRackPinionGearBox, setsteeringRackPinionGearBox ] = useState('') 
         const [ airCompressorclutch, setairCompressorclutch ] = useState('') 
         const [ airCondensor, setairCondensor ] = useState('') 
         const [ airModeControl, setairModeControl ] = useState('') 
         const [ electricalChargingSystem, setelectricalChargingSystem ] = useState('') 
         const [ electricalStarter, setelectricalStarter ] = useState('') 
         const [ electricalPowerWindowMotor, setelectricalPowerWindowMotor ] = useState('') 
         const [ electricalPowerSeatsMotor, setelectricalPowerSeatsMotor ] = useState('') 
         const [ electricalHorn, setelectricalHorn ] = useState('') 
         const [ electricalPowerLocks, setelectricalPowerLocks ] = useState('') 
         const [ electricalPowerRoofMotor, setelectricalPowerRoofMotor ] = useState('') 
         const [ electricalPowerAntenaAudio, setelectricalPowerAntenaAudio ] = useState('') 
         const [ electricalCruiseControl, setelectricalCruiseControl ] = useState('') 
         const [ electricalPowerMirrors, setelectricalPowerMirrors ] = useState('') 
         const [ electricalRearDefrost, setelectricalRearDefrost ] = useState('') 
         const [ electricalWiperMotorBlades, setelectricalWiperMotorBlades ] = useState('') 
         const [ electricalLights, setelectricalLights ] = useState('') 
         const [ comments, setcomments ] = useState('') 



    function  ChangeHandleridInspection   (event)   { setidInspection( event.target.value)} 
    function  ChangeHandlerlincense   (event)   { setlincense( event.target.value)} 
    function  ChangeHandlerdate   (event)   { setdate( event.target.value)} 
    function  ChangeHandleryear   (event)   { setyear( event.target.value)} 
    function  ChangeHandlermake   (event)   { setmake( event.target.value)} 
    function  ChangeHandlermodel   (event)   { setmodel( event.target.value)} 
    function  ChangeHandlervin   (event)   { setvin( event.target.value)} 
    function  ChangeHandlerodometer   (event)   { setodometer( event.target.value)} 



    function  ChangeHandlerCoolingWaterPump                    (event)   { setcoolingWaterPump( event.target.value)} 
    function  ChangeHandlerCoolingEngineFanMotor               (event)   { setcoolingEngineFanMotor( event.target.value)}
    function  ChangeHandlerCoolingFanClutchShroud              (event)   { setcoolingFanClutchShroud( event.target.value)}
    function  ChangeHandlerCoolingCoolantRecoveryUnit          (event)   { setcoolingCoolantRecoveryUnit( event.target.value)}
    function  ChangeHandlerCoolingBeltsHoses                   (event)   { setcoolingBeltsHoses( event.target.value)}
    function  ChangeHandlerCoolingTemperatureLightGauge        (event)   { setcoolingTemperatureLightGauge( event.target.value)}
    function  ChangeHandlerCoolingFluidLeaks                   (event)   { setcoolingFluidLeaks( event.target.value)}
    function  ChangeHandlerCoolingFuelPumpTankLines            (event)   { setcoolingFuelPumpTankLines( event.target.value)}
    function  ChangeHandlerCoolingDieselInjectorsPump          (event)   { setcoolingDieselInjectorsPump( event.target.value)}
    function  ChangeHandlerTransmissionFluidLevelCondition     (event)   { settransmissionFluidLevelCondition( event.target.value)}
    function  ChangeHandlerTransmissionShiftPoints             (event)   { settransmissionShiftPoints( event.target.value)}
    function  ChangeHandlerTransmissionSlippingBinding         (event)   { settransmissionSlippingBinding( event.target.value)}
    function  ChangeHandlerTransmissionNoise                   (event)   { settransmissionNoise( event.target.value)}
    function  ChangeHandlerTransmissionMounts                  (event)   { settransmissionMounts( event.target.value)}
    function  ChangeHandlerTransmissionFluidLeaks              (event)   { settransmissionFluidLeaks( event.target.value)}
    function  ChangeHandlerBrakeFluidLeaksLinesFiltering       (event)   { setbrakeFluidLeaksLinesFiltering( event.target.value)}
    function  ChangeHandlerBrakeMasterCylinder                 (event)   { setbrakeMasterCylinder( event.target.value)}
    function  ChangeHandlerBrakePumpBooster                    (event)   { setbrakePumpBooster( event.target.value)}
    function  ChangeHandlerBrakeLiningsFronts                  (event)   { setbrakeLiningsFronts( event.target.value)}
    function  ChangeHandlerBrakeLiningsRears                   (event)   { setbrakeLiningsRears( event.target.value)}
    function  ChangeHandlerTreadLeftFront                      (event)   { settreadLeftFront( event.target.value)}
    function  ChangeHandlerTreadLeftRear                       (event)   { settreadLeftRear( event.target.value)}
    function  ChangeHandlerEnginePerformance                   (event)   { setenginePerformance( event.target.value)}
    function  ChangeHandlerEngineNoise                         (event)   { setengineNoise( event.target.value)}
    function  ChangeHandlerEngineOilPressureLightGauge         (event)   { setengineOilPressureLightGauge( event.target.value)}
    function  ChangeHandlerEngineMotorMounts                   (event)   { setengineMotorMounts( event.target.value)}
    function  ChangeHandlerEngineExhaustLeak                   (event)   { setengineExhaustLeak( event.target.value)}
    function  ChangeHandlerEngineOilLevelCondition             (event)   { setengineOilLevelCondition( event.target.value)}
    function  ChangeHandlerEngineOilLeaks                      (event)   { setengineOilLeaks( event.target.value)}
    function  ChangeHandlerEngineOilSmoke                      (event)   { setengineOilSmoke( event.target.value)}
    function  ChangeHandlerDriveVibration                      (event)   { setdriveVibration( event.target.value)}
    function  ChangeHandlerDriveNoise                          (event)   { setdriveNoise( event.target.value)}
    function  ChangeHandlerDriveFluidLeaks                     (event)   { setdriveFluidLeaks( event.target.value)}
    function  ChangeHandlerDriveCVUJoints                      (event)   { setdriveCVUJoints( event.target.value)}
    function  ChangeHandlerSuspensionControlHarms              (event)   { setsuspensionControlHarms( event.target.value)}
    function  ChangeHandlerSuspensionBallJoints                (event)   { setsuspensionBallJoints( event.target.value)}
    function  ChangeHandlerSuspensionWheelBearings             (event)   { setsuspensionWheelBearings( event.target.value)}
    function  ChangeHandlerSuspensionTorsionBarsSprings        (event)   { setsuspensionTorsionBarsSprings( event.target.value)}
    function  ChangeHandlerSuspensionStabilizerShaftLinks      (event)   { setsuspensionStabilizerShaftLinks( event.target.value)}
    function  ChangeHandlerSuspensionMacPhersonStruts          (event)   { setsuspensionMacPhersonStruts( event.target.value)}
    function  ChangeHandlerSteeringFluidLevelLeak              (event)   { setsteeringFluidLevelLeak( event.target.value)}
    function  ChangeHandlerSteeringPump                        (event)   { setsteeringPump( event.target.value)}
    function  ChangeHandlerSteeringLinkage                     (event)   { setsteeringLinkage( event.target.value)}
    function  ChangeHandlerSteeringRackPinionGearBox           (event)   { setsteeringRackPinionGearBox( event.target.value)}
    function  ChangeHandlerAirCompressorclutch                 (event)   { setairCompressorclutch( event.target.value)}
    function  ChangeHandlerAirCondensor                        (event)   { setairCondensor( event.target.value)}
    function  ChangeHandlerAirModeControl                      (event)   { setairModeControl( event.target.value)}
    function  ChangeHandlerElectricalChargingSystem            (event)   { setelectricalChargingSystem ( event.target.value)}
    function  ChangeHandlerElectricalStarter                   (event)   { setelectricalStarter( event.target.value)}
    function  ChangeHandlerElectricalPowerWindowMotor          (event)   { setelectricalPowerWindowMotor( event.target.value)}
    function  ChangeHandlerElectricalPowerSeatsMotor           (event)   { setelectricalPowerSeatsMotor( event.target.value)}
    function  ChangeHandlerElectricalHorn                      (event)   { setelectricalHorn( event.target.value)}
    function  ChangeHandlerElectricalPowerLocks                (event)   { setelectricalPowerLocks( event.target.value)}
    function  ChangeHandlerElectricalPowerRoofMotor            (event)   { setelectricalPowerRoofMotor( event.target.value)}
    function  ChangeHandlerElectricalPowerAntenaAudio          (event)   { setelectricalPowerAntenaAudio( event.target.value)}
    function  ChangeHandlerElectricalCruiseControl             (event)   { setelectricalCruiseControl( event.target.value)}
    function  ChangeHandlerElectricalPowerMirrors              (event)   { setelectricalPowerMirrors( event.target.value)}
    function  ChangeHandlerElectricalRearDefrost               (event)   { setelectricalRearDefrost( event.target.value)}
    function  ChangeHandlerElectricalWiperMotorBlades          (event)   { setelectricalWiperMotorBlades( event.target.value)}
    function  ChangeHandlerElectricalLights                    (event)   { setelectricalLights( event.target.value)}
    function  ChangeHandlerComments                            (event)   { setcomments( event.target.value)}


         function print(){
             window.print()
         }   

      
         function styles(item) {
            if(item === "NS") {
                return "red"
            } if(item === "2-4 mm"){
                return "red"
            } if(item === "4-6 mm"){
                return "red"
            } if(item === "2-4 / 32"){
                return "red"
            } if(item === "4-6 / 32"){
                return "red"
            }
            else {
                return "black"
            }
         }

         const authentication = () => {    

            const data = {
             "IdInspection":                    idInspection,
             "Lincense":                        lincense,
             "Date":                            date,
             "Year":                            year,
             "Make":                            make,
             "Model":                           model,
             "Vin":                             vin,
             "Odometer":                        odometer,
             "CoolingWaterPump":                coolingWaterPump,
             "CoolingEngineFanMotor":           coolingEngineFanMotor,
             "CoolingFanClutchShroud":          coolingFanClutchShroud,
             "CoolingCoolantRecoveryUnit":      coolingCoolantRecoveryUnit,
             "CoolingBeltsHoses":               coolingBeltsHoses,
             "CoolingTemperatureLightGauge":    coolingTemperatureLightGauge,
             "CoolingFluidLeaks":               coolingFluidLeaks,
             "CoolingFuelPumpTankLines":        coolingFuelPumpTankLines,
             "CoolingDieselInjectorsPump":      coolingDieselInjectorsPump,
             "TransmissionFluidLevelCondition": transmissionFluidLevelCondition,
             "TransmissionShiftPoints":         transmissionShiftPoints,
             "TransmissionSlippingBinding":     transmissionSlippingBinding,
             "TransmissionNoise":               transmissionNoise,
             "TransmissionMounts":              transmissionMounts,
             "TransmissionFluidLeaks":          transmissionFluidLeaks,
             "BrakeFluidLeaksLinesFiltering":   brakeFluidLeaksLinesFiltering,
             "BrakeMasterCylinder":             brakeMasterCylinder,
             "BrakePumpBooster":                brakePumpBooster,
             "BrakeLiningsFronts":              brakeLiningsFronts,
             "BrakeLiningsRears":               brakeLiningsRears,
             "TreadLeftFront":                  treadLeftFront,
             "TreadLeftRear":                   treadLeftRear,
             "EnginePerformance":               enginePerformance,
             "EngineNoise":                     engineNoise,
             "EngineOilPressureLightGauge":     engineOilPressureLightGauge,
             "EngineMotorMounts":               engineMotorMounts,
             "EngineExhaustLeak":               engineExhaustLeak,
             "EngineOilLevelCondition":         engineOilLevelCondition,
             "EngineOilLeaks":                  engineOilLeaks,
             "EngineOilSmoke":                  enginePerformance,
             "DriveVibration":                  driveVibration,
             "DriveNoise":                      driveNoise,
             "DriveFluidLeaks":                 driveFluidLeaks,
             "DriveCVUJoints":                  driveCVUJoints,
             "SuspensionControlHarms":          suspensionControlHarms,
             "SuspensionBallJoints":            suspensionBallJoints,
             "SuspensionWheelBearings":         suspensionWheelBearings,
             "SuspensionTorsionBarsSprings":    suspensionTorsionBarsSprings,
             "SuspensionStabilizerShaftLinks":  suspensionStabilizerShaftLinks,
             "SuspensionMacPhersonStruts":      suspensionMacPhersonStruts,
             "SteeringFluidLevelLeak":          steeringFluidLevelLeak,
             "SteeringPump":                    steeringPump,
             "SteeringLinkage":                 steeringLinkage,
             "SteeringRackPinionGearBox":       steeringRackPinionGearBox,
             "AirCompressorclutch":             airCompressorclutch,
             "AirCondensor":                    airCondensor,
             "AirModeControl":                  airModeControl,
             "ElectricalChargingSystem":        electricalChargingSystem,
             "ElectricalStarter":               electricalStarter,
             "ElectricalPowerWindowMotor":      electricalPowerWindowMotor,
             "ElectricalPowerSeatsMotor":       electricalPowerSeatsMotor,
             "ElectricalHorn":                  electricalHorn,
             "ElectricalPowerLocks":            electricalPowerLocks,
             "ElectricalPowerRoofMotor":        electricalPowerRoofMotor,
             "ElectricalPowerAntenaAudio":      electricalPowerAntenaAudio,
             "ElectricalCruiseControl":         electricalCruiseControl,
             "ElectricalPowerMirrors":          electricalPowerMirrors,
             "ElectricalRearDefrost":           electricalRearDefrost,
             "ElectricalWiperMotorBlades":      electricalWiperMotorBlades,
             "ElectricalLights":                electricalLights,
             "Comments":                        comments,
           }  
           
           Axios.post(`${process.env.REACT_APP_BASE_URL}${user.uid}/${process.env.REACT_APP_CREATEINSPECTION}/${user.uid}/data.json`, data)
           .then(r => {
             localStorage.setItem('token', r.data.token)
             window.location = "/"
           }).then(() => alert('The Estimate has been succesfully saved'))
           .catch(e => {
             alert('error al iniciar sesion')
           } )
         }
         
    return(
        <Container>
            <form>
            <Row style={{marginTop:20}}>
                <Col className='text-center'>
                    <div>
                      <Link to='/account'>
                          <img style={{width:300, height:120}} src={logo} alt='...' />
                      </Link>
                    </div>
                </Col>
                <Col>
                <h5 className="text-center">{businessname}</h5>
                    <p className="text-center">{bstreet}<br/>
                    {bcity}<br/>{bphone}</p>
                </Col>
                <Col>
                <h5 className='text-center'>VEHICLE</h5>
                    <h5 className='text-center'>INSPECTION REPORT</h5>
                    <div className='text-right'>
                    <span>Date: </span> 
                    <input style={{width:'150px'}} type="date" id="Date" name="Date" onChange={ChangeHandlerdate} />
                    </div>
                    <div className='text-right'>
                    <span>Inspection No: </span> 
                    <input style={{width:'150px'}} type="number" id="IdInspection" name="IdInspection" onChange={ChangeHandleridInspection} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{marginTop:10}}>
                <table className="table table-sm" style={{margin:0}}>                  
                    <tbody>
                      <tr>                        
                        <td style={{width:'100px'}}><TextField inputProps={{maxLength: 7}}  label='Lincense Plate No:'  id="Lincense" name='Lincense' type='text'  variant="standard" onChange={ChangeHandlerlincense} /></td>
                        <td style={{width:'100px'}}><Autocomplete
                              id="lYear"
                              options={dbData.year}
                              getOptionLabel={(option) => option.title}
                           
                              renderInput={(params) => <TextField {...params} id="Year" name="Year" type='text' label="Year: "  variant="standard"  onSelect={ChangeHandleryear} />}
                            /></td>
                        <td style={{width:'100px'}}><Autocomplete
                              id="lMake"
                              options={dbMakes.makes}
                              getOptionLabel={(option) => option.title}
                           
                              renderInput={(params) => <TextField {...params} id="Make" name="Make" type='text' label="Make: "  variant="standard" onSelect={ChangeHandlermake} />}
                            /></td>
                        <td style={{width:'100px'}}><Autocomplete
                              id="lModel"
                              options={dbModels.models}
                              getOptionLabel={(option) => option.title}
                           
                              renderInput={(params) => <TextField {...params} id="Model" name="Model" type='text' label="Model: "  variant="standard"  onSelect={ChangeHandlermodel} />}
                            /></td>                            
                        <td style={{width:'140px'}}><TextField inputProps={{ maxLength: 20}}  fullWidth label='VIN'  id="Vin" name='Vin' type='text'  variant="standard" onChange={ChangeHandlervin} /></td>
                        <td style={{width:'100px'}}><TextField inputProps={{maxLength: 7}}  label='Odometer Reading'  id="Odometer" name='Odometer' type='text'  variant="standard" onChange={ChangeHandlerodometer} /></td>
                                           
                      </tr>
                    </tbody>                                        
                    </table> 
                    <p style={{textAlign:'center', margin:0}}><strong>Note:</strong> This inspection is effective on the date of inspection and for vehicle mileage at inspection, as indicated above. 
                        Checked items under "Needs Service" may need service or repair, see technician's comments on reverse side.</p>                   
                    <table className="table  table-sm">                    
                    <tbody>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>1. Cooling & Fuel System</h6></td> 
                        <td style={{width:'80px'}}><span>OK / NS</span></td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>4. Engine</h6></td>                         
                         <td style={{width:'80px'}}><span>OK / NS</span></td>                        
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10,  fontWeight:'bold'}}>7. Steering</h6></td> 
                        <td style={{width:'80px'}}><span>OK / NS</span></td>
                      </tr>
                      
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Water Pump</h6></td>                         
                        <td style={{width:'80px'}}>                       
                        <select className="custom-select" style={{color: styles(coolingWaterPump)}} id="CoolingWaterPump"  onChange={ChangeHandlerCoolingWaterPump}  >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Engine Performance</h6></td>                         
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(enginePerformance)}} id="EnginePerformance" onChange={ChangeHandlerEnginePerformance}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Fluid Level / Leak</h6></td> 
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(steeringFluidLevelLeak)}} id="SteeringFluidLevelLeak" onChange={ChangeHandlerSteeringFluidLevelLeak}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr> 
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Engine Fan Motor</h6></td>                         
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(coolingEngineFanMotor)}} id="CoolingEngineFanMotor" onChange={ChangeHandlerCoolingEngineFanMotor}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                         <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Noise</h6></td> 
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(engineNoise)}} id="EngineNoise" onChange={ChangeHandlerEngineNoise}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Pump</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(steeringPump)}} id="SteeringPump" onChange={ChangeHandlerSteeringPump}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                      </tr> 
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Fan & Clutch/Shroud</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(coolingFanClutchShroud)}} id="CoolingFanClutchShroud" onChange={ChangeHandlerCoolingFanClutchShroud}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Oil Pressure Light/Gauge</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(engineOilPressureLightGauge)}} id="EngineOilPressureLightGauge" onChange={ChangeHandlerEngineOilPressureLightGauge}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Linkage</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(steeringLinkage)}} id="SteeringLinkage" onChange={ChangeHandlerSteeringLinkage}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Coolant Recovery Unit</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(coolingCoolantRecoveryUnit)}} id="CoolingCoolantRecoveryUnit" onChange={ChangeHandlerCoolingCoolantRecoveryUnit}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Motor Mounts</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(engineMotorMounts)}} id="EngineMotorMounts" onChange={ChangeHandlerEngineMotorMounts}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Rack & Pinion/Gear Box</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(steeringRackPinionGearBox)}} id="SteeringRackPinionGearBox" onChange={ChangeHandlerSteeringRackPinionGearBox}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select> 
                            </td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>E. Belts & Hoses</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(coolingBeltsHoses)}} id="CoolingBeltsHoses" onChange={ChangeHandlerCoolingBeltsHoses}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>E. Exhaust Leak</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(engineExhaustLeak)}} id="EngineExhaustLeak" onChange={ChangeHandlerEngineExhaustLeak} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>8. Air Conditioning</h6></td>
                        <td style={{width:'80px' }}></td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>F. Temperature Light/Gauge</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(coolingTemperatureLightGauge)}} id="CoolingTemperatureLightGauge" onChange={ChangeHandlerCoolingTemperatureLightGauge}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>F. Oil Level Condition</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(engineOilLevelCondition)}} id="EngineOilLevelCondition" onChange={ChangeHandlerEngineOilLevelCondition}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Compressor/clutch</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(airCompressorclutch)}} id="AirCompressorclutch" onChange={ChangeHandlerAirCompressorclutch}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>G. Fluid Leaks</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(coolingFluidLeaks)}} id="CoolingFluidLeaks" onChange={ChangeHandlerCoolingFluidLeaks}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>G. Oil Leaks</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(engineOilLeaks)}} id="EngineOilLeaks" onChange={ChangeHandlerEngineOilLeaks}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Condensor</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(airCondensor)}} id="AirCondensor" onChange={ChangeHandlerAirCondensor}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>H. Fuel Pump/Tank & Lines</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(coolingFuelPumpTankLines)}} id="CoolingFuelPumpTankLines" onChange={ChangeHandlerCoolingFuelPumpTankLines}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>H. Oil Smoke</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(engineOilSmoke)}} id="EngineOilSmoke" onChange={ChangeHandlerEngineOilSmoke}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Mode Control</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(airModeControl)}} id="AirModeControl" onChange={ChangeHandlerAirModeControl}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>I. Diesel Injectors & Pump</h6></td>
                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(coolingDieselInjectorsPump)}} id="CoolingDieselInjectorsPump" onChange={ChangeHandlerCoolingDieselInjectorsPump}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>5. Drive Axle</h6></td>

                        
                        <td style={{width:'80px'}}></td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>9. Electrical System</h6></td>
                        <td style={{width:'80px' }}></td>
                      </tr>
                      <tr>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>2. Transmission/Transfer Case</h6></td>
                        <td style={{width:'80px'}}></td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Vibration</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(driveVibration)}} id="DriveVibration" onChange={ChangeHandlerDriveVibration}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Charging System</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(electricalChargingSystem)}} id="ElectricalChargingSystem" onChange={ChangeHandlerElectricalChargingSystem}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Fluid Level/Condition</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(transmissionFluidLevelCondition)}} id="TransmissionFluidLevelCondition" onChange={ChangeHandlerTransmissionFluidLevelCondition}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Noise</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(driveNoise)}} id="DriveNoise" onChange={ChangeHandlerDriveNoise} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Starter</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(electricalStarter)}} id="ElectricalStarter" onChange={ChangeHandlerElectricalStarter} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Shift Points (if automatic)</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(transmissionShiftPoints)}} id="TransmissionShiftPoints" onChange={ChangeHandlerTransmissionShiftPoints} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Fluid Leaks</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(driveFluidLeaks)}} id="DriveFluidLeaks" onChange={ChangeHandlerDriveFluidLeaks} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Power Window Motor</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(electricalPowerWindowMotor)}} id="ElectricalPowerWindowMotor" onChange={ChangeHandlerElectricalPowerWindowMotor} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Slipping / Binding</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(transmissionSlippingBinding)}} id="TransmissionSlippingBinding" onChange={ChangeHandlerTransmissionSlippingBinding} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. CV/U Joints</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(driveCVUJoints)}} id="DriveCVUJoints" onChange={ChangeHandlerDriveCVUJoints} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Power Seats Motor/H</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(electricalPowerSeatsMotor)}} id="ElectricalPowerSeatsMotor" onChange={ChangeHandlerElectricalPowerSeatsMotor} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Noise</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(transmissionNoise)}} id="TransmissionNoise" onChange={ChangeHandlerTransmissionNoise} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                             </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>6. Suspension</h6></td>
                        
                        <td style={{width:'80px'}}></td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>E. Horn</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(electricalHorn)}} id="ElectricalHorn" onChange={ChangeHandlerElectricalHorn} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>E. Transmission Mounts</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(transmissionMounts)}} id="TransmissionMounts" onChange={ChangeHandlerTransmissionMounts} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select> </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Control Harms</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(suspensionControlHarms)}} id="SuspensionControlHarms" onChange={ChangeHandlerSuspensionControlHarms} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>F. Power Locks</h6></td>
                        <td style={{width:'80px' }}>
                        <select className="custom-select" style={{color: styles(electricalPowerLocks)}} id="ElectricalPowerLocks" onChange={ChangeHandlerElectricalPowerLocks} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>F. Fluid Leaks</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(transmissionFluidLeaks)}} id="TransmissionFluidLeaks" onChange={ChangeHandlerTransmissionFluidLeaks} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Ball Joints</h6></td>                        
                        <td style={{width:'80px'}}>
                        <select className="custom-select" style={{color: styles(suspensionBallJoints)}} id="SuspensionBallJoints" onChange={ChangeHandlerSuspensionBallJoints} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>G. Power Roof Motor</h6></td>
                        <td style={{width:'80px' }}>
                            <select className="custom-select" style={{color: styles(electricalPowerRoofMotor)}} id="ElectricalPowerRoofMotor" onChange={ChangeHandlerElectricalPowerRoofMotor} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr > 
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, fontWeight:'bold'}}>3. Brake System</h6></td>
                        <td style={{width:'80px'}}></td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Wheel Bearings</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(suspensionWheelBearings)}} id="SuspensionWheelBearings" onChange={ChangeHandlerSuspensionWheelBearings} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>H. Power Antena/Audio S.</h6></td>
                        <td style={{width:'80px' }}>
                            <select className="custom-select" style={{color: styles(electricalPowerAntenaAudio)}} id="ElectricalPowerAntenaAudio" onChange={ChangeHandlerElectricalPowerAntenaAudio} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select> 
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>A. Fluid/Leaks/Lines/Filtering</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(brakeFluidLeaksLinesFiltering)}} id="BrakeFluidLeaksLinesFiltering" onChange={ChangeHandlerBrakeFluidLeaksLinesFiltering} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>D. Torsion Bars/Springs</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(suspensionTorsionBarsSprings)}} id="SuspensionTorsionBarsSprings" onChange={ChangeHandlerSuspensionTorsionBarsSprings} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select> 
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>I. Cruise Control</h6></td>
                        <td style={{width:'80px' }}>
                            <select className="custom-select" style={{color: styles(electricalCruiseControl)}} id="ElectricalCruiseControl" onChange={ChangeHandlerElectricalCruiseControl} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr >
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>B. Master Cylinder</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(brakeMasterCylinder)}} id="BrakeMasterCylinder" onChange={ChangeHandlerBrakeMasterCylinder}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>E. Stabilizer Shaft & Links</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(suspensionStabilizerShaftLinks)}} id="SuspensionStabilizerShaftLinks" onChange={ChangeHandlerSuspensionStabilizerShaftLinks}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>J. Power Mirrors</h6></td>
                        <td style={{width:'80px' }}>
                            <select className="custom-select" style={{color: styles(electricalPowerMirrors)}} id="ElectricalPowerMirrors" onChange={ChangeHandlerElectricalPowerMirrors}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>
                      <tr>
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>C. Pump/Booster</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(brakePumpBooster)}} id="BrakePumpBooster" onChange={ChangeHandlerBrakePumpBooster}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>F. MacPherson Struts</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(suspensionMacPhersonStruts)}} id="SuspensionMacPhersonStruts" onChange={ChangeHandlerSuspensionMacPhersonStruts}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>K. Rear Defrost</h6></td>
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(electricalRearDefrost)}} id="ElectricalRearDefrost" onChange={ChangeHandlerElectricalRearDefrost}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr> 
                      <tr>
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>Brake Linings Fronts</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(brakeLiningsFronts)}} id="BrakeLiningsFronts" onChange={ChangeHandlerBrakeLiningsFronts}>
                                <option value=""></option>
                                <option value="2-4 mm">2-4 mm</option>
                                <option value="4-6 mm">4-6 mm</option>
                                <option value="6-8 mm">6-8 mm</option>
                                <option value="8-10 mm">8-10 mm</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>Brake Linings Rears</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(brakeLiningsRears)}} id="BrakeLiningsRears"  onChange={ChangeHandlerBrakeLiningsRears} >
                                <option value=""></option>
                                <option value="2-4 mm">2-4 mm</option>
                                <option value="4-6 mm">4-6 mm</option>
                                <option value="6-8 mm">6-8 mm</option>
                                <option value="8-10 mm">8-10 mm</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>L. Wiper Motor/Blades</h6></td>
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(electricalWiperMotorBlades)}} id="ElectricalWiperMotorBlades"  onChange={ChangeHandlerElectricalWiperMotorBlades} >
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>  
                      <tr>
                      <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>Tread Left-Front</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(treadLeftFront)}} id="TreadLeftFront" onChange={ChangeHandlerTreadLeftFront} >
                                <option value=""></option>
                                <option value="2-4 / 32">2-4 / 32</option>
                                <option value="4-6 / 32">4-6 / 32</option>
                                <option value="6-8 / 32">6-8 / 32</option>
                                <option value="8-10 / 32">8-10 / 32</option>
                                <option value="10-12 / 32">10-12 / 32</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>Tread Left-Rear</h6></td>                        
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(treadLeftRear)}} id="TreadLeftRear" onChange={ChangeHandlerTreadLeftRear}>
                                <option value=""></option>
                                <option value="2-4 / 32">2-4 / 32</option>
                                <option value="4-6 / 32">4-6 / 32</option>
                                <option value="6-8 / 32">6-8 / 32</option>
                                <option value="8-10 / 32">8-10 / 32</option>
                                <option value="10-12 / 32">10-12 / 32</option>
                            </select>
                            </td>
                        <td style={{width:'200px', textAlign:'left'}}><h6 style={{marginTop:10, marginLeft:10}}>M. Lights</h6></td>
                        <td style={{width:'80px'}}>
                            <select className="custom-select" style={{color: styles(electricalLights)}} id="ElectricalLights" onChange={ChangeHandlerElectricalLights}>
                                <option value=""></option>
                                <option value="Ok">Ok</option>
                                <option value="NS">NS</option>
                            </select>
                            </td>
                      </tr>            
                    </tbody>
                    </table>
                </Col>
            </Row>
            <div className="text-center" style={{marginBottom:"50px"}}><span>1 - 2</span></div>            
        
        <Row className='mb-5' style={{marginTop:"150px"}}>           
            <Col md={12} className='text-left'>
                <h6>MECHANICS COMMENTS:</h6>                       
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">System</th>
                          <th scope="col" className="text-center">Detail</th>
                        </tr>
                      </thead>
                      <tbody>
                        { coolingWaterPump               === "NS" && <tr><td>Cooling & Fuel System</td><td>Water Pump</td></tr> }
                        { coolingEngineFanMotor          === "NS" && <tr><td>Cooling & Fuel System</td><td>Engine Fan Motor</td></tr> }
                        { coolingFanClutchShroud         === "NS" && <tr><td>Cooling & Fuel System</td><td>Fan Clutch Shroud</td></tr> }
                        { coolingCoolantRecoveryUnit     === "NS" && <tr><td>Cooling & Fuel System</td><td>Coolant Recovery Unit</td></tr> }
                        { coolingBeltsHoses              === "NS" && <tr><td>Cooling & Fuel System</td><td>Belts Hoses</td></tr> }
                        { coolingTemperatureLightGauge   === "NS" && <tr><td>Cooling & Fuel System</td><td>Temperature Light Gauge</td></tr> }
                        { coolingFluidLeaks              === "NS" && <tr><td>Cooling & Fuel System</td><td>Fluid Leaks</td></tr> }
                        { coolingFuelPumpTankLines       === "NS" && <tr><td>Cooling & Fuel System</td><td>Fuel Pump Tank Lines</td></tr> }
                        { coolingDieselInjectorsPump     === "NS" && <tr><td>Cooling & Fuel System</td><td>Diesel Injectors Pump</td></tr> }


                        { transmissionFluidLevelCondition === "NS" && <tr><td>Transmission / Transfer Case</td><td>Fluid Level Condition</td></tr> }
                        { transmissionShiftPoints         === "NS" && <tr><td>Transmission / Transfer Case</td><td>Shift Points         </td></tr> }
                        { transmissionSlippingBinding     === "NS" && <tr><td>Transmission / Transfer Case</td><td>Slipping Binding     </td></tr> }
                        { transmissionNoise               === "NS" && <tr><td>Transmission / Transfer Case</td><td>Noise                </td></tr> }
                        { transmissionMounts              === "NS" && <tr><td>Transmission / Transfer Case</td><td>Mounts               </td></tr> }
                        { transmissionFluidLeaks          === "NS" && <tr><td>Transmission / Transfer Case</td><td>Fluid Leaks          </td></tr> }


                        { brakeFluidLeaksLinesFiltering   === "NS" && <tr><td>Brake System</td><td>Fluid Leaks Lines Filtering </td></tr> }
                        { brakeMasterCylinder             === "NS" && <tr><td>Brake System</td><td>Master Cylinder             </td></tr> }
                        { brakePumpBooster                === "NS" && <tr><td>Brake System</td><td>Pump Booster                </td></tr> }
                        { brakeLiningsFronts === "2-4 mm" && <tr><td>Brake System</td><td>Linings Fronts {brakeLiningsFronts} </td></tr> }
                        { brakeLiningsFronts === "4-6 mm" && <tr><td>Brake System</td><td>Linings Fronts {brakeLiningsFronts} </td></tr> }
                        { brakeLiningsRears  === "2-4 mm" && <tr><td>Brake System</td><td>Linings Rears {brakeLiningsRears}  </td></tr> }
                        { brakeLiningsRears  === "4-6 mm" && <tr><td>Brake System</td><td>Linings Rears {brakeLiningsRears}  </td></tr> }
                        { treadLeftFront     === "2-4 / 32"  && <tr><td>Brake System</td><td>Tread Left Front {treadLeftFront}  </td></tr> }
                        { treadLeftFront     === "4-6 / 32"  && <tr><td>Brake System</td><td>Tread Left Front {treadLeftFront}  </td></tr> }
                        { treadLeftRear      === "2-4 / 32"  && <tr><td>Brake System</td><td>TreadLeft Rear {treadLeftRear}  </td></tr> }
                        { treadLeftRear      === "4-6 / 32"  && <tr><td>Brake System</td><td>Tread Left Rear {treadLeftRear}   </td></tr> }


                        { enginePerformance               === "NS" && <tr><td>Engine</td><td>Performance               </td></tr> }
                        { engineNoise                     === "NS" && <tr><td>Engine</td><td>Noise                     </td></tr> }
                        { engineOilPressureLightGauge     === "NS" && <tr><td>Engine</td><td>Oil Pressure ight Gauge   </td></tr> }
                        { engineMotorMounts               === "NS" && <tr><td>Engine</td><td>Motor Mounts              </td></tr> }
                        { engineExhaustLeak               === "NS" && <tr><td>Engine</td><td>Exhaust Leak              </td></tr> }
                        { engineOilLevelCondition         === "NS" && <tr><td>Engine</td><td>Oil Level Condition       </td></tr> }
                        { engineOilLeaks                  === "NS" && <tr><td>Engine</td><td>Oil Leaks                 </td></tr> }
                        { engineOilSmoke                  === "NS" && <tr><td>Engine</td><td>Oil Smoke                 </td></tr> }


                        { driveVibration                   === "NS" && <tr><td>Drive Axle</td><td>Vibration   </td></tr> }
                        { driveNoise                       === "NS" && <tr><td>Drive Axle</td><td>Noise       </td></tr> }
                        { driveFluidLeaks                  === "NS" && <tr><td>Drive Axle</td><td>Fluid Leaks </td></tr> }
                        { driveCVUJoints                   === "NS" && <tr><td>Drive Axle</td><td>CV/U Joints </td></tr> }

                        { suspensionControlHarms           === "NS" && <tr><td>Suspension</td><td>Control Harms          </td></tr> }
                        { suspensionBallJoints             === "NS" && <tr><td>Suspension</td><td>Ball Joints            </td></tr> }
                        { suspensionWheelBearings          === "NS" && <tr><td>Suspension</td><td>Wheel Bearings         </td></tr> }
                        { suspensionTorsionBarsSprings     === "NS" && <tr><td>Suspension</td><td>Torsion Bars Springs   </td></tr> }
                        { suspensionStabilizerShaftLinks   === "NS" && <tr><td>Suspension</td><td>Stabilizer Shaft Links </td></tr> }
                        { suspensionMacPhersonStruts       === "NS" && <tr><td>Suspension</td><td>MacPherson Struts      </td></tr> }


                        { steeringFluidLevelLeak          === "NS" && <tr><td>Steering</td><td>Fluid Level Leak     </td></tr> }
                        { steeringPump                    === "NS" && <tr><td>Steering</td><td>Pump                 </td></tr> }
                        { steeringLinkage                 === "NS" && <tr><td>Steering</td><td>Linkage              </td></tr> }
                        { steeringRackPinionGearBox       === "NS" && <tr><td>Steering</td><td>Rack Pinion Gear Box </td></tr> }


                        { airCompressorclutch      === "NS" && <tr><td>Air Conditioning</td><td>Compressor clutch  </td></tr> }
                        { airCondensor             === "NS" && <tr><td>Air Conditioning</td><td>Condensor          </td></tr> }
                        { airModeControl           === "NS" && <tr><td>Air Conditioning</td><td>Mode Control       </td></tr> }


                        { electricalChargingSystem     === "NS" && <tr><td>Electrical System</td><td>Charging System  </td></tr> }
                        { electricalStarter            === "NS" && <tr><td>Electrical System</td><td>Starter         </td></tr> }
                        { electricalPowerWindowMotor   === "NS" && <tr><td>Electrical System</td><td>Power Window Motor</td></tr> }
                        { electricalPowerSeatsMotor    === "NS" && <tr><td>Electrical System</td><td>Power Seats Motor </td></tr> }
                        { electricalHorn               === "NS" && <tr><td>Electrical System</td><td>Horn            </td></tr> }
                        { electricalPowerLocks         === "NS" && <tr><td>Electrical System</td><td>Power Locks      </td></tr> }
                        { electricalPowerRoofMotor     === "NS" && <tr><td>Electrical System</td><td>Power Roof Motor  </td></tr> }
                        { electricalPowerAntenaAudio   === "NS" && <tr><td>Electrical System</td><td>Power Antena Audio</td></tr> }
                        { electricalCruiseControl      === "NS" && <tr><td>Electrical System</td><td>Cruise Control   </td></tr> }
                        { electricalPowerMirrors       === "NS" && <tr><td>Electrical System</td><td>Power Mirrors    </td></tr> }
                        { electricalRearDefrost        === "NS" && <tr><td>Electrical System</td><td>Rear Defrost     </td></tr> }
                        { electricalWiperMotorBlades   === "NS" && <tr><td>Electrical System</td><td>Wiper Motor Blades</td></tr> }
                        { electricalLights             === "NS" && <tr><td>Electrical System</td><td>Lights          </td></tr> }


                      </tbody>
                     </table>
                    
                    <span>Comments:</span>
                    <textarea  
                      style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                       id="Comments" 
                       name="Comments" 
                       type='text' 
                       onChange={ChangeHandlerComments}
                    />               
                  
            </Col>
        </Row> 
        <Row>
            <Col md={4} className='text-center' >
                <span><strong>{bemail}</strong></span>
            </Col>
            <Col md={4} className='text-center'>
                <h5 ><strong>Thanks for choosing us.</strong></h5>
            </Col>
            <Col md={4} >
            <Row style={{marginBottom:25}}>
                <Col>
                   <Button                                
                        type="button"
                         variant="outlined"
                         color="primary"
                         size="small"
                         className="primary"
                         startIcon={<SaveIcon />}    
                         onClick={authentication}              
                        >
                         Save
                    </Button>
                </Col>
                <Col>
                    <Button    
                           onClick={print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>

              </Row>
            </Col>
        </Row>
        </form>
        </Container>
    )
}


export default Inspection
