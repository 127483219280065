import React, {useState, useEffect} from 'react'
import {auth } from './auth/firebase'
import { login, logout, selectUser } from './auth/users/userSlice'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Header from './components/Header'
import HeaderBlock from './components/HeaderBlock'
import Menu from './components/Menu'
import Login from './pages/Login';
import Account from './pages/Account';
// import InvoiceLast from './pages/InvoiceLast';
import Estimate from './pages/Estimate';
import SearchInvoice from './pages/SearchInvoice';
import Error404 from './pages/Error404';
import Booking from './pages/Booking'
import Inspection from './pages/Inspection'
import SearchEstimate from './pages/SearchEstimate'
import Profiles from './pages/Profiles'
import CashPay from './components/modalprofile/CashPay'
import Ourclientinvoice from './pages/Ourclientinvoice'
import GottaWebDesign from './pages/GottaWebDesign'
import Balance from './pages/Balance'
import SignUp from './pages/SignUp'
import InvoiceTowing from './pages/InvoiceTowing'
// import SignUp from './components/SignUp/SignUp'

function App() {
  const user = useSelector(selectUser)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        // User is signed in
        dispatch(
          login({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
          })
        )
      } else {
        // User is signed out
        dispatch(logout())
      }
    })
  }, [dispatch])
  
  return (
    <Router>
      <div className='app'>
        <Switch>
          <Route exact path='/'>
            <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            {isMenuOpen && <Menu />}
            <HeaderBlock />
          </Route> 
          <Route exact path='/login'>
            {user ? <Redirect to='/account' /> : <Login />}
          </Route>  
          <Route exact path='/account'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <Account
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route>  
          <Route exact path='/signup'>           
                <SignUp
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
          </Route> 
          <Route exact path='/account/ourclientinvoice'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <Ourclientinvoice
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route> 
          <Route exact path='/account/gottawebdesign'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <GottaWebDesign
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route> 
          <Route exact path='/account/invoice'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <InvoiceTowing
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route>   
          <Route exact path='/account/estimate'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <Estimate
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route> 
          <Route exact path='/account/searchinvoice'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <SearchInvoice
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route>
          <Route exact path='/account/searchestimate'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <SearchEstimate
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route>
          <Route exact path='/account/inspection'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <Inspection
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route>
          <Route exact path='/account/booking'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <Booking
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route>
          <Route exact path='/account/profiles'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <Profiles
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route>
          <Route exact path='/account/balance'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <Balance
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route>
          <Route exact path='/account/profiles/cashpayroll'>
            {!user ? (
              <Redirect to='/login' />
            ) : (
              <>
                <CashPay
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
                {isMenuOpen && <Menu />}
              </>
            )}
          </Route>
          <Route  path='/'>           
                <Error404 />
          </Route>          
        </Switch>
      </div>
    </Router>
    
  )
}

export default App




// import React from 'react'
// import Dashboard from "./components/Dashboard/Dashboard";
// import SignUp from "./components/SignUp/SignUp";
// import Login from "./components/Login/Login";
// import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
// import Profile from "./components/Dashboard/Profile/Profile"
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
// import { AuthProvider } from "./context/AuthContext"
// import PriveteRoute from "./PrivateRoute"
// import "./index.css";

// const App = () => {


//   return (
//     <>
//     <Router>
//       <AuthProvider>
//         <Switch>
//           <PriveteRoute exact path="/" component={Dashboard} />
//           <PriveteRoute path="/profile" component={Profile} />
//           <Route path="/signup" component={SignUp} />
//           <Route path="/login" component={Login} />
//           <Route path="/forgot-password" component={ForgotPassword} />
//         </Switch>
//       </AuthProvider>
//       </Router>
//     </>
//   )
// }

// export default App

