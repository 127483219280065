import React, {useState} from "react";
import { useHistory } from "react-router-dom";

import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import * as profilesActions from '../../store/actions/profile'
import { useSelector, useDispatch } from 'react-redux'
import {selectUser } from '../../auth/users/userSlice'

function Checkout(props) {

    const id = props.idhandler 
    const item = props.itemhandler
    const user = useSelector(selectUser)

    let history = useHistory();
    function historyhandleClick() {
      history.push("/account");
    }

    const dispatch = useDispatch()
        
    const [checkout, setCheckout] = useState('')

    function getHandler(e) {
        setCheckout(e.target.value)
    }    

    const data = {
        checkout: checkout,
        active: false
    }    

    function submitHandler() {
        const useee = user.uid
        dispatch(profilesActions.updateProfileDetail(useee, id, item, data))
        historyhandleClick()
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Check Out 
          </Modal.Title>
        </Modal.Header>
        { id ?
          <Modal.Body>
        <Form>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCheckout">
                  <Form.Label>Check Out</Form.Label>
                  <Form.Control type="time" placeholder="Enter Check Out" onChange={getHandler} />
                </Form.Group>
            </Row>  
              <Button variant="info" type="submit" onClick={submitHandler}>
                Submit
              </Button> 
            </Form>
        </Modal.Body> :
         <Modal.Body>
         <Form>
             <Row className="mb-3">
                 <Form.Group as={Col} controlId="ueryuece">
                   <Form.Label>You already have an entry date registered.</Form.Label>
                 </Form.Group>
             </Row>  
             <Button onClick={props.onHide}>Close</Button> 
             </Form>
         </Modal.Body>
        }
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Close</Button> */}
        </Modal.Footer>
      </Modal>
    );
  }

  export default Checkout
