import React, {useEffect, useState} from 'react'
import firebase from "firebase";
import { db } from '../auth/firebase'
import { useLocation, useHistory } from "react-router-dom"
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../auth/users/userSlice'
import * as  invoicesActions from '../store/actions/invoice'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import dbdata from "../db/dbdata.json"

// import logo from '../assets/image/logo.png'
import { Divider } from '@material-ui/core';
// import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'


const Ourclientinvoice = (props) => {

    const user = useSelector(selectUser)

    const [url, setUrl] = useState(null);
    const [todos, setTodos] = useState([]);
  
    //Get profile picture from firebase
    const getProfileImage = () => {
      // firebase.storage().ref(`users/${user.uid}/cropped-GottaWebDesign-02-6-scaled-345x219-1-160x93.jpg`).getDownloadURL().then(imgUrl => {setUrl(imgUrl)})
      firebase
      .storage()
      .ref('users')
      .child(user.uid + "/cropped-GottaWebDesign-02-6-scaled-345x219-1-160x93.jpg")
      .getDownloadURL()
      .then(imgUrl => {
        setUrl(imgUrl);
      });
    }
  
    useEffect(() => {
      setTimeout(() => {
        getProfileImage();
      }, 1000);
    }, [])
  
  
    
  
   // Get Todos
   const  getTodos = () => {
     db.collection(user.uid).onSnapshot(function(querySnapshot) {
       setTodos(
         querySnapshot.docs.map((doc) => ({
           id: doc.id,
           bcity: doc.data().bcity,
           bemail: doc.data().bemail,
           bstreet: doc.data().bstreet,
           businessname: doc.data().businessname,
           bphone: doc.data().bphone
         }))
       );
     });
   }

   useEffect(() => {
    getTodos();    
  }, []) // blank to run only on first time

    const location = useLocation()
    const item = location.state?.id

    let history = useHistory();

    function historyhandleClick() {
      history.push("/account");
    }


    const searchByPhone = useSelector(state => state.invoices.invoices)

    const dispatch = useDispatch()

    const [ idinvoice, setidinvoice ] = useState('')
    const [ name, setname] = useState('')
    const [ phone, setphone] = useState('')
    const [ date, setdate] = useState('')
    const [ balance, setbalance] = useState('')
    const [ payment, setpayment] = useState('')
    const [ recommend, setrecommend] = useState('')

    const [des1, setdes1] = useState('')
    const [pri1, setpri1] = useState('')
    const [qua1, setqua1] = useState('')

    const [des2, setdes2] = useState('')
    const [pri2, setpri2] = useState('')
    const [qua2, setqua2] = useState('')


    const [des3, setdes3] = useState('')
    const [pri3, setpri3] = useState('')
    const [qua3, setqua3] = useState('')

    const [des4, setdes4] = useState('')
    const [pri4, setpri4] = useState('')
    const [qua4, setqua4] = useState('')

    const [des5, setdes5] = useState('')
    const [pri5, setpri5] = useState('')
    const [qua5, setqua5] = useState('')

    const [des6, setdes6] = useState('')
    const [pri6, setpri6] = useState('')
    const [qua6, setqua6] = useState('')
    const [amo, setamo] = useState('')


    useEffect(() => {
        const data = searchByPhone.find(x => x.id === item)
        setname(data.Name)
        setphone(data.Phone)

    },[searchByPhone, item])


    useEffect(() => {
        const idinvoicelast = searchByPhone ? parseInt(searchByPhone[searchByPhone.length - 1].Idinvoice) + 1 : ''        
        setidinvoice(idinvoicelast.toString())

    },[searchByPhone])

    const myChangeHandlerdate           = (event) => { setdate(event.target.value)} 
    const myChangeHandlerbalance        = (event) => { 
        setbalance(event.target.value)
    } 
    const myChangeHandlerrecommend      = (event) => { setrecommend(event.target.value)} 

    
  

    
      const myChangeHandlerdes1 = (event) => {
        setdes1(event.target.value);    
      }
      const myChangeHandlerdes2 = (event) => {
        setdes2(event.target.value);    
      }
      
      const myChangeHandlerdes3 = (event) => {
        setdes3(event.target.value);    
      }
      const myChangeHandlerdes4 = (event) => {
        setdes4(event.target.value);    
      }
      const myChangeHandlerdes5 = (event) => {
        setdes5(event.target.value);    
      }
      const myChangeHandlerdes6 = (event) => {
        setdes6(event.target.value);    
      }
      const myChangeHandlerpri1 = (event) => {
        setpri1(event.target.value);    
      }
      const myChangeHandlerqua1 = (event) => {
        setqua1( event.target.value);    
      }     
      const myChangeHandlerpri2 = (event) => {
        setpri2( event.target.value);    
      }
      const myChangeHandlerqua2 = (event) => {
        setqua2(event.target.value);    
      }
      const myChangeHandlerpri3 = (event) => {
        setpri3( event.target.value);    
      }
      const myChangeHandlerqua3 = (event) => {
        setqua3( event.target.value);    
      }
      const myChangeHandlerpri4 = (event) => {
        setpri4( event.target.value);    
      }
      const myChangeHandlerqua4 = (event) => {
        setqua4( event.target.value);    
      }
      const myChangeHandlerpri5 = (event) => {
        setpri5( event.target.value);    
      }
      const myChangeHandlerqua5 = (event) => {
        setqua5( event.target.value);    
      }
      const myChangeHandlerpri6 = (event) => {
        setpri6(event.target.value);    
      }
      const  myChangeHandlerqua6 = (event) => {
        setqua6( event.target.value);    
      }
     
     const myChangeHandleramo = (event) => {
        setamo( event.target.value);    
      }
      
     function myChangeHandlerpayment(event) { setpayment(event.target.value)}


       function print(){
        window.print();
    }



    const data = {
        "Idinvoice": idinvoice,
        "Name":      name,
        "Phone":     phone,
        "Date":      date,
        "Balance":  (((((pri1 ? parseInt(pri1) : 0) * (qua1 ? parseInt(qua1) : 0)) + 
                       ((pri2 ? parseInt(pri2) : 0) * (qua2 ? parseInt(qua2) : 0)) +
                       ((pri3 ? parseInt(pri3) : 0) * (qua3 ? parseInt(qua3) : 0)) + 
                       ((pri4 ? parseInt(pri4) : 0) * (qua4 ? parseInt(qua4) : 0)) + 
                       ((pri5 ? parseInt(pri5) : 0) * (qua5 ? parseInt(qua5) : 0)) + 
                       ((pri6 ? parseInt(pri6) : 0) * (qua6 ? parseInt(qua6) : 0))))).toFixed(2),

        "Payment":  payment,
        "Recommend":recommend,
          "Lines_details": [         
              {
                 "Des": des1,
                 "Pri": pri1,
                 "Qua": qua1,
              },
              { 
                 "Des": des2,
                 "Pri": pri2,
                 "Qua": qua2,
              },
              {
                 "Des": des3,
                 "Pri": pri3,
                 "Qua": qua3,
              },
              {
                 "Des": des4,
                 "Pri": pri4,
                 "Qua": qua4,
              },
              {
                 "Des": des5,
                 "Pri": pri5,
                 "Qua": qua5,
              },
              {
                 "Des": des6,
                 "Pri": pri6,
                 "Qua": qua6,
               }
          ]
      }  

      function sutmitHandlersave() {
        dispatch(invoicesActions.createInvoice(user.uid, data))
        historyhandleClick()
    }


    return (
        <>
        <Container style={{marginTop:'40px'}}>   
            <form>
          <Row className='mb-3'>
            <Col sm={3} className='text-center'> 
            <div >
                <Link to='/account'>
                 {
                   url != null ?
                   <img               
                  style={{width: '200px', height:100}}
                  src={url}
                  alt='..'  /> :
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                 }
                </Link>
            </div>
             </Col>
            <Col sm={6} className="text-center" > 
            <h2 className="text-center">{todos[0] ? todos[0].businessname : ''}</h2>
                <p className="text-center">{todos[0] ? todos[0].bstreet : ''}<br/>
                {todos[0] ? todos[0].bcity : ''}<br/>{todos[0] ? todos[0].bphone : ''}</p>
            </Col>
            <Col sm={3} className="text-center"> 
            <h3 >Invoice</h3>
            </Col>
        </Row>
        <Divider variant="middle" />
        <Divider className='mb-3' variant="middle" />
        <Row className='mb-3'>        
            <Col md={4}> 
              <Row>
                <Col md={4}>
                  <h5 style={{marginTop:12}}>Bill To:</h5>
                </Col>
                <Col >
                <TextField  id="Name" name='Name' type='text' label="Full Name" variant="standard"  value={name}  />
                </Col>
              </Row>
              <Row>
                <Col  md={4}>
                  <h5 style={{marginTop:12}}>Phone:</h5>
                </Col>
                <Col>
                <TextField id="Phone" name="Phone" type='text' label="Phone Number" variant="standard"  value={phone} />
                </Col>
              </Row>
            </Col>
            <Col md={{ span: 4, offset: 4 }}> 
                <Row>
                    <Col md={6}>
                    <h5>Invoice No:</h5>
                    </Col>
                    <Col md={6}>
                    <TextField  id="Idinvoice" name="Idinvoice" type='number'  variant="standard"  value={idinvoice}  />
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                    <h5>Date:</h5>
                    </Col>
                    <Col md={5}>
                    <TextField  id="Date" name="Date" type='date'  variant="standard" value={date} onChange={myChangeHandlerdate} />
                    </Col>
                </Row>
            </Col>
            
        </Row>        
       
        <Row className='mb-5' >
        <table className="table  table-sm ">
            <thead>
              <tr className='text-center'>
                <th style={{width:'60px', borderBottomColor:'black'}}>#</th>
                <th style={{width:'700px', borderBottomColor:'black'}}>Description</th>
                <th style={{width:'130px', borderBottomColor:'black'}}>Price $</th>
                <th style={{width:'130px', borderBottomColor:'black'}}>Quantity</th>
                <th style={{width:'130px', borderBottomColor:'black'}}>Total $</th>
              </tr>
            </thead>
            <tbody style={{borderBottomColor:'white'}}>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>1</td>
                <td><TextField multiline  fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}} id="Des1" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center'}}} id="Pri1" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua1" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua1} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0) ).toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>2</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des2" name="Des" type='text' label="" variant="standard"  onChange={myChangeHandlerdes2}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri2" name="Pri" type='number' label="" variant="standard" onChange={myChangeHandlerpri2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua2" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua2} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) ).toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center' >3</td>
                <td ><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des3" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri3" name="Pri" type='number' label="" variant="standard" onChange={myChangeHandlerpri3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua3" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua3} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) ).toFixed(2)}</td>

              </tr> 
             
                <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>4</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des4" name="Des" type='text' label="" variant="standard"  onChange={myChangeHandlerdes4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri4" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua4" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua4} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) ).toFixed(2)}</td>
              </tr> 
              
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>5</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des5" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri5" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua5" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua5} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) ).toFixed(2)}</td>
              </tr> 
             
               <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>6</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des6" name="Des" type='text' label="" variant="standard" onChange={myChangeHandlerdes6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri6" name="Pri" type='number' label="" variant="standard"  onChange={myChangeHandlerpri6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua6" name="Qua" type='number' label="" variant="standard" onChange={myChangeHandlerqua6} /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) ).toFixed(2)}</td>
              </tr>                     
              <tr>
                <td className='text-center' style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black'}} className='text-left' ><h6><strong>Total $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' >
                    <h6>
                    <strong>
                    {
                        (((pri1 ? parseInt(pri1) * parseInt(qua1) : 0) ) + 
                        ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) ) +
                        ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) ) + 
                        ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) ) +
                        ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) ) + 
                        ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) )).toFixed(2)

                    }
                    </strong>
                    </h6>
                </td>
              </tr>   
              <tr>
                <td className='text-center' style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderBottomColor:'white'}} ></td>
                <td style={{borderColor:'black'}} className='text-left'><h6><strong>Paid Amount $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' ><strong>
                  <TextField inputProps={{min: 0, style: { textAlign: 'center' }}}  
                  id="amo" type='number' label="" 
                  name="amo"
                  placeholder="  0.00"
                  variant="standard"  
                  onChange={myChangeHandleramo}/></strong></td>
              </tr> 
              <tr>
                <td className='text-center' style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}><Autocomplete
                      id="idpayment"
                      options={ dbdata.payment}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params} id="Payment" name="Payment" type='text' label="Payment methodo" variant="standard" onSelect={myChangeHandlerpayment} />}
                    /> </td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-left'><h6><strong>Balance Due $</strong></h6></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-center' >                
                  <strong>
                  <TextField inputProps={{min: 0, style: { textAlign: 'center', fontWeight: 'bold', fontSize: "large" }}} id="Balance" name="Balance"  type='number' label="" variant="standard" 
                  value = 
                  {
                   ((((pri1 ?  parseInt(pri1) * parseInt(qua1) : 0) ) + 
                     ((pri2 ?  parseInt(pri2) * parseInt(qua2) : 0) ) +
                     ((pri3 ?  parseInt(pri3) * parseInt(qua3) : 0) ) + 
                     ((pri4 ?  parseInt(pri4) * parseInt(qua4) : 0) ) + 
                     ((pri5 ?  parseInt(pri5) * parseInt(qua5) : 0) ) + 
                     ((pri6 ?  parseInt(pri6) * parseInt(qua6) : 0) )) - (amo ? parseInt(amo) : 0)).toFixed(2)
                  }
                /></strong>                  
                </td>
              </tr>  
            </tbody>
            </table>
        </Row>       
        <Row className='mb-2'>
            <Col md={6} className='text-center'>
                <h5>Customer signature</h5>
            </Col>
            <Col md={6} className='text-center'>
                <h5>{todos[0] ? todos[0].businessname : ''}</h5>                
            </Col>
        </Row>    
        <Row className='mb-5'>           
            <Col md={12} className='text-left'>
                <h6>RECOMMENDATIONS:</h6>
                <textarea  
                  style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                   id="Recommend" 
                   name="Recommend" 
                   type='text' 
                   onChange={myChangeHandlerrecommend}
                />               
                  
            </Col>
        </Row> 
        <Row>
            <Col md={4} className='text-center' >
                <span><strong>{todos[0] ? todos[0].bemail : ''}</strong></span>
            </Col>
            <Col md={4} className='text-center'>
                <h5 ><strong>Thanks for choosing us.</strong></h5>
            </Col>
            <Col md={4} >
            <Row>
                <Col>
                   <Button                                
                        type="button"
                         variant="outlined"
                         color="primary"
                         size="small"
                         className="primary"
                         startIcon={<SaveIcon />}   
                         onClick={sutmitHandlersave}               
                        >
                         Save
                    </Button>
                </Col>
                <Col>
                    <Button    
                           onClick={print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>

              </Row>
            </Col>
        </Row>
        </form>
      </Container>
      </>
        
    )
}

export default Ourclientinvoice

